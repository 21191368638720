import jwt from 'jwt-decode'
import { useEffect, useState } from 'react'
import { fetchAndSetData, fetchData } from '../api/Axios'

export const GetUsers = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        const abortCont = new AbortController()
        
        fetchAndSetData("/api/users", "Benutzer konnten nicht geladen werden.", setUsers, abortCont.signal)
    
        return () => abortCont.abort()
    }, [])

    return { users }
}

export const useCurrentUser = () => {
    return useUser(getUUID())
}

export const useUser = (uuid) => {
    const [name, setName] = useState(uuid)
    const [email, setEmail] = useState("")
    useEffect(() => {
        if(uuid !== null) {
            const abortCont = new AbortController()
            
            fetchData("/api/users/" + uuid, "Benutzer konnte nicht geladen werden.", data => {
                setName(data.name)
                setEmail(data.email)
            }, abortCont.signal)
        
            return () => abortCont.abort()
        }
    }, [uuid])

    return { uuid, name, setName, email, setEmail }
}

export function getUUID() {
    var uuid = null
    const token = getTokenData()
    if(token !== null) {
        uuid = token.uuid
    }

    return uuid
}

export function getEMail() {
    var email = null
    const token = getTokenData()
    if(token !== null) {
        email = token.email
    }

    return email
}

export function isLoggedIn() {
    return getTokenData() !== null
}

export function getTokenData() {
    var token = getToken()
    if(token) {
        try {
            var data = jwt(token)
            if(data.exp > Math.floor(Date.now() / 1000)) {
                return data
            }
        } catch(e) {
        }
    }

    return null
}

export function getToken() {
    var token = sessionStorage.getItem('token')
    if(token === null) {
        token = localStorage.getItem('token')
    }

    return token
}

export const usePermissions = (email) => {
    const [permissions, setPermissions] = useState({})
    useEffect(() => {
        const abortCont = new AbortController()
        
        fetchData(
            "/api/permissions" + (email !== null && email !== "" ? "/" + email : ""),
            "Berechtigungen konnten nicht geladen werden.",
            data => setPermissions(data.permissions),
            abortCont.signal
        )
    
        return () => abortCont.abort()
    }, [email])

    return { permissions, setPermissions }
}