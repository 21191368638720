import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const TicketList = ({filter}) => {
    const columns =  [{
        Header: "Bearbeiten"
    }, {
        Header: "Status",
        accessor: "state_text",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Ersteller",
        accessor: "creator_name"
    }, {
        Header: "Erstellt",
        accessor: "created_formatted"
    }, {
        Header: "Bearbeiter",
        accessor: "supporter_name"
    }, {
        Header: "Kategorie",
        accessor: "category",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Anfrage",
        accessor: "request"
    }, {
        Header: "Antwort",
        accessor: "answer"
    }, {
        Header: "Server",
        accessor: "server",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Position",
        accessor: (row, _) => {
            return row.x + " " + row.y + " " + row.z + (row.world == null ? "" : " @ " + row.world)
        }
    }, {
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]

    const [tickets, setTickets] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/tickets" + (filter === undefined ? "" : "/" + filter), "Tickets konnten nicht geladen werden.", setTickets)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={tickets} editUrlPart="ticket" />
        </div>
    )
}
   
export default TicketList