import axios from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getToken } from '../user/User'

export const useTicket = (id) => {
    const [created, setCreated] = useState(0)
    const [createdFormatted, setCreatedFormatted] = useState("")
    const [state, setState] = useState("open")
    const [stateText, setStateText] = useState("")
    const [creator, setCreator] = useState("")
    const [creatorName, setCreatorName] = useState("")
    const [supporter, setSupporter] = useState("")
    const [supporterName, setSupporterName] = useState("")
    const [server, setServer] = useState("")
    const [world, setWorld] = useState("world")
    const [x, setX] = useState(0)
    const [y, setY] = useState(64)
    const [z, setZ] = useState(0)
    const [category, setCategory] = useState("")
    const [request, setRequest] = useState("")
    const [answer, setAnswer] = useState("")
    useEffect(() => {
        if(id !== null) {
            const abortCont = new AbortController()
            
            axios.get(process.env.REACT_APP_API_URL + "/api/tickets/" + id, {
                signal: abortCont.signal,
                headers: {
                    "Authorization": "Bearer " + getToken()
                }
            }).then(res => {
                setAnswer(res.data.answer === null ? "" : res.data.answer)
                setCategory(res.data.category)
                setCreated(res.data.created)
                setCreatedFormatted(res.data.created_formatted)
                setCreator(res.data.creator)
                setCreatorName(res.data.creator_name)
                setRequest(res.data.request)
                setServer(res.data.server)
                setState(res.data.state)
                setStateText(res.data.state_text)
                setSupporter(res.data.supporter === null ? "" : res.data.supporter)
                setSupporterName(res.data.supporter_name === null ? "" : res.data.supporter_name)
                setWorld(res.data.world)
                setX(res.data.x)
                setY(res.data.y)
                setZ(res.data.z)
            }).catch(error => {
                console.error(error)
                if(axios.isCancel(error)) {
                  return
                }
                if (error.response) {
                    var statusCode = error.response.status
                    if(statusCode === 400) {
                        toast.error("Ungültige Anfrage.")
                        return
                    } else if(statusCode === 404) {
                        toast.error("Es existiert kein Tikcet mit der Id " + id + ".")
                        return
                    }
                }
                toast.error("Serverfehler")
            })
        
            return () => abortCont.abort()
        }
    }, [id])

    return { created, setCreated, createdFormatted, setCreatedFormatted, state, setState, stateText, setStateText, creator, setCreator, creatorName, setCreatorName, supporter, setSupporter, 
        supporterName, setSupporterName, server, setServer, world, setWorld, x, setX, y, setY, z, setZ, category, setCategory, request, setRequest, answer, setAnswer }
}