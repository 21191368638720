import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getEMail, usePermissions } from "../../user/User"
import BasicTableComponent from "../../components/basic.table"
import { toast } from "react-toastify"
import { fetchAndSetData } from "../../api/Axios"

const UserList = () => {
    const navigate = useNavigate()

    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.user !== undefined && !permissions.user) {
            toast.error("Du hast keine Erlaubnis Benutzer zu sehen!")
            navigate("/")
        }
    }, [permissions])

    const handleCreate = async e => {
        e.preventDefault()
        navigate("/user/create")
    }

    const columns =  [{
        Header: "E-Mail-Adresse",
        accessor: "email"
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "UUID",
        accessor: "uuid"
    }, {
        Header: "Bearbeiten"
    }]

    const [users, setUsers] = useState([])

    useEffect(() => {
        fetchAndSetData("/api/users", "Benutzer konnten nicht geladen werden.", setUsers)
    }, [])

    return (
        <div>
            <button className="btn btn-primary" onClick={handleCreate}>Benutzer erstellen</button>

            <BasicTableComponent columns={columns} data={users} editUrlPart="user" />
        </div>
    )
}
   
export default UserList
