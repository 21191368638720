import React, { useEffect, useState } from "react"
import BasicTableComponent, { SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const ClientList = ({filter}) => {
    const columns =  [{
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Client",
        accessor: "client",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }]

    const [clients, setClients] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/clients" + (filter === undefined ? "" : "/" + filter), "Clients konnten nicht geladen werden.", setClients)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={clients} editUrlPart="client" />
        </div>
    )
}
   
export default ClientList