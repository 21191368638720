import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const ParticipantList = ({filter}) => {
    const columns =  [{
        Header: "UUID",
        accessor: "uuid"
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Gefolge",
        accessor: "faction_name",
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Rang",
        accessor: "rank",
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Geld",
        accessor: "money",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Steuerverwarnungen",
        accessor: "warns",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Spielt seit",
        accessor: (row, _) => {
            return new Date(row.created).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Ist Ritter",
        accessor: (row, _) => {
            return row.is_knight ? "Ja" : "Nein"
        },
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Ist eingesperrt",
        accessor: (row, _) => {
            return row.is_jailed ? "Ja" : "Nein"
        },
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Eingesperrt von",
        accessor: "jail_faction_name",
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Ist Priester",
        accessor: (row, _) => {
            return row.is_priest ? "Ja" : "Nein"
        },
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Ist gejagt",
        accessor: (row, _) => {
            return row.is_hunted ? "Ja" : "Nein"
        },
        Filter: SelectColumnFilter,
        filter: 'includes'
    }]

    const [participants, setParticipants] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/participants" + (filter === undefined ? "" : "/" + filter), "Spieler konnten nicht geladen werden.", setParticipants)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={participants} editUrlPart="participant" />
        </div>
    )
}
   
export default ParticipantList