import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const MoneyMovementList = ({filter}) => {
    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Erstellt",
        accessor: (row, _) => {
            return new Date(row.created).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Richtung",
        accessor: "direction",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Ursache",
        accessor: "cause",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Geld",
        accessor: "value",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]

    const [moneyMovements, setMoneyMovements] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/moneymovements" + (filter === undefined ? "" : "/" + filter), "Geldbewegungen konnten nicht geladen werden.", setMoneyMovements)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={moneyMovements} editUrlPart="moneymovement" />
        </div>
    )
}
   
export default MoneyMovementList