import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const FactionList = ({filter}) => {
    const columns =  [{
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Banner",
        accessor: "banner_name"
    }, {
        Header: "Anführer",
        accessor: "leader_name"
    }, {
        Header: "Mitglieder",
        accessor: "member_count",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Geld",
        accessor: "money",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Einfluss",
        accessor: "influence",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Region",
        accessor: "region_name"
    }, {
        Header: "Steuern",
        accessor: "taxes",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Steuerverwarnungen",
        accessor: "warns",
        Filter: NumberRangeColumnFilter,
        filter: 'between'
    }, {
        Header: "Rang",
        accessor: "rank_name",
        Filter: SelectColumnFilter,
        filter: 'includes'
    }, {
        Header: "Lehensherr",
        accessor: "lord_name"
    }, {
        Header: "Erstellt",
        accessor: (row, _) => {
            return new Date(row.creation_date_timestamp).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }]

    const [factions, setFactions] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/factions" + (filter === undefined ? "" : "/" + filter), "Gefolge konnten nicht geladen werden.", setFactions)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={factions} editUrlPart="faction" />
        </div>
    )
}
   
export default FactionList