import React, { useEffect, useState } from "react"
import BasicTableComponent, { SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const CharacterHistoryList = ({filter}) => {
    const columns =  [{
        Header: "Erstellt am",
        accessor: (row, _) => {
            return new Date(row.created * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Name",
        accessor: "name"
    }, {
        Header: "Geschlecht",
        accessor: "geschlecht",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Alter",
        accessor: "alter",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Aussehen",
        accessor: "aussehen"
    }]

    const [characters, setCharacters] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/character" + (filter === undefined ? "" : "/" + filter), "Charaktere konnten nicht geladen werden.", data => {
            setCharacters(data.history)
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={characters} editUrlPart="character" />
        </div>
    )
}
   
export default CharacterHistoryList