import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const PunishmentList = ({filter}) => {
    const columns =  [{
        Header: "Id",
        accessor: "id",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Typ",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Erstellt",
        accessor: (row, _) => {
            return new Date(row.created * 1000).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"
        }
    }, {
        Header: "Ersteller",
        accessor: "creatorName"
    }, {
        Header: "Spieler",
        accessor: "punishedName"
    }, {
        Header: "Grund",
        accessor: "reason"
    }, {
        Header: "Zeit",
        accessor: "time"
    }]

    const [punishments, setPunishments] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/punishments" + (filter === undefined ? "" : "/" + filter), "Verwarnungen konnten nicht geladen werden.", setPunishments)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={punishments} editUrlPart="punishment" />
        </div>
    )
}
   
export default PunishmentList