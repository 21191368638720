import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { fetchAndSetData } from "../../api/Axios"

const OnlinetimeList = ({filter}) => {
    const [onlinetimes, setOnlinetimes] = useState([])

    const columns =  [{
        Header: "Spieler",
        accessor: "player_name"
    }, {
        Header: "Spielzeit in Sekunden",
        accessor: "online_time",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Spielzeit",
        accessor: (row, _) => {
            var sec_num = row.online_time
            var days    = Math.floor(sec_num / 86400)
            sec_num     -= days * 86400
            var hours   = Math.floor(sec_num / 3600)
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60)
            var seconds = sec_num - (hours * 3600) - (minutes * 60)
        
            var dayString = days === 0 ? '' : days + ' Tage '
            var hoursString = hours === 0 ? '' : hours + ' Stunden '
            var minutesString = minutes === 0 ? '' : minutes + ' Minuten '
            return dayString + hoursString + minutesString + seconds + ' Sekunden'
        }
    }]

    useEffect(() => {
        fetchAndSetData("/api/onlinetime" + (filter === undefined ? "" : "?player=" + filter), "Spielzeiten konnten nicht geladen werden.", setOnlinetimes)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={onlinetimes} editUrlPart="onlinetime" />
        </div>
    )
}
   
export default OnlinetimeList