import React, { useEffect, useState } from "react"
import BasicTableComponent, { NumberRangeColumnFilter, SelectColumnFilter } from "../../components/basic.table"
import { fetchData } from "../../api/Axios"

const PointsList = ({filter}) => {
    const columns =  [{
        Header: "Punktetyp",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: 'includes',
    }, {
        Header: "Spielername",
        accessor: "player_name"
    }, {
        Header: "Punkte",
        accessor: "points",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }, {
        Header: "Spieler UUID",
        accessor: "uuid",
    }, {
        Header: "Bearbeiten"
    }]

    const [points, setPoints] = useState([])
    useEffect(() => {
        fetchData("/api/points" + (filter === undefined ? "" : "/" + filter), "Punkte konnten nicht geladen werden.", data => {
            let dataList = []

            data.forEach(pointsEntry => {
                pointsEntry.stats.forEach(statsEntry => {
                    dataList.push({
                        "name": pointsEntry.name,
                        "player_name": statsEntry.player_name,
                        "points": statsEntry.points,
                        "uuid": statsEntry.player_uuid,
                    })
                })
            })

            setPoints(dataList)
        })
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={points} editUrlPart="points" />
        </div>
    )
}
   
export default PointsList