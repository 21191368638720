import { useEffect, useState } from "react"
import Autocomplete from "react-autocomplete"
import TicketList from "../ticket/TicketList"
import CharacterHistoryList from "../character/CharacterHistoryList"
import PunishmentList from "../punishment/PunishmentList"
import ClientList from "../client/ClientList"
import AddressList from "../address/AddressList"
import PointsList from "../points/PointsList"
import { useTime } from "../../time/Time"
import { useParams } from "react-router-dom"
import { fetchAndSetData, fetchData } from "../../api/Axios"

const PlayerSearch = () => {
    const [player, setPlayer] = useState("")
    const [users, setUsers] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [onlinetime, setOnlinetime] = useState(0)
    const { time: playTime } = useTime(onlinetime, 4)
    const [participantData, setParticipantData] = useState([])
    const [characterData, setCharacterData] = useState([])
    const [deaths, setDeaths] = useState(0)
    const [kills, setKills] = useState(0)
    const [mobKills, setMobKills] = useState(0)
    const [solvedQuests, setSolvedQuests] = useState(0)
    const [solvedActivities, setSolvedActivities] = useState(0)

    const [averageTimePerDay, setAverageTimePerDay] = useState(0)
    const { time: averagePlayTimePerDay } = useTime(averageTimePerDay, 4)

    let optionalPlayer = useParams()
    useEffect(() => {
        if(optionalPlayer.player != null) {
            setPlayer(optionalPlayer.player)
            loadPlayer(optionalPlayer.player)
        }
    }, [optionalPlayer])
    
    useEffect(() => {
        if(onlinetime != 0 && characterData != [] && characterData.current != undefined) {
            const differenceInMilliseconds = new Date().getTime() - characterData.current.first_played;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            setAverageTimePerDay(parseInt(onlinetime / differenceInDays))
        }
    }, [onlinetime, characterData])

    async function updatePlayer(newPlayer) {
        setOnlinetime(0)
        setPlayer(newPlayer)
        setIsLoaded(false)
        if(newPlayer === "") {
            return
        }
        fetchAndSetData("/api/players/" + newPlayer, "Spieler konnten nicht geladen werden.", setUsers)
    }

    const loadPlayer = (playerToLoad) => {
        if(playerToLoad == null) {
            playerToLoad = player
        }
        if(playerToLoad === "") {
            return
        }

        setOnlinetime(0)
        fetchData("/api/onlinetime?player=" + playerToLoad, "Spielzeiten konnten nicht geladen werden.", data => setOnlinetime(data[0].online_time))

        fetchAndSetData("/api/participant/" + playerToLoad, "Spielerdaten konnten nicht geladen werden.", setParticipantData)

        fetchAndSetData("/api/character/" + playerToLoad, "Charakterdaten konnten nicht geladen werden.", setCharacterData)

        fetchData("/api/mcstats?player=" + playerToLoad + "&stats=DEATHS,PLAYER_KILLS,MOB_KILLS", "Minecraft Statistiken konnten nicht geladen werden.", data => {
            setDeaths(data.DEATHS)
            setKills(data.PLAYER_KILLS)
            setMobKills(data.MOB_KILLS)
        })

        fetchData("/api/stats?player=" + playerToLoad + "&stats=Quest&format=INT", "Quest Statistiken konnten nicht geladen werden.", data => setSolvedQuests(data.amount))

        fetchData("/api/stats?player=" + playerToLoad + "&stats=Aktivität&format=INT", "Aktivität Statistiken konnten nicht geladen werden.", data => setSolvedActivities(data.amount))

        setIsLoaded(true)
    }

    return (
        <div className="row">
            <div className="col-12" style={{zIndex: "100", marginBottom: "20px"}}>
                <Autocomplete
                    wrapperStyle={{cursor: "pointer", float: "left"}}
                    getItemValue={(item) => item.label}
                    items={users.map(user => {
                        return {label: user}
                    })}
                    renderItem={(item, isHighlighted) =>
                        <div key={item.label} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            {item.label}
                        </div>
                    }
                    value={player}
                    onChange={(e) => updatePlayer(e.target.value)}
                    onSelect={(selected) => setPlayer(selected)}
                />
                <button type="submit" className="btn btn-primary" onClick={() => loadPlayer()}>Spieler laden</button>
            </div>

            {!isLoaded ? "" : 
            <>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Charakter</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                UUID: {participantData["uuid"] == null ? characterData.current == null ? "" : characterData.current.player_uuid : participantData["uuid"]}<br />
                                Name: {participantData["name"] == null ? characterData.current == null ? "" : characterData.current.player_name : participantData["name"]}<br />
                                Titel (Stand): {participantData["title"]} {participantData["state"] == null ? "" : "(" + participantData["state"] + ")"}<br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                {characterData.current == null ? "" : 
                                <>Charaktername: {characterData.current.name}<br />
                                Geschlecht: {characterData.current.geschlecht}<br />
                                Alter: {characterData.current.alter}<br />
                                Aussehen: {characterData.current.aussehen}<br /></>}
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Gefolge: {participantData["faction_name"]}<br />
                                Rang: {participantData["rank"]}<br />
                                Geld: {participantData["money"]}<br />
                                Steuerverwarnungen: {participantData["warns"]}<br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Ritter: {participantData["is_knight"] ? "Ja" : "Nein"}<br />
                                Verfolgt: {participantData["is_hunted"] ? "Ja" : "Nein"}<br />
                                Eingesperrt: {participantData["is_jailed"] ? "Ja (" + participantData["jail_faction_name"] + ")" : "Nein"}<br />
                                Priester: {participantData["is_priest"] ? "Ja" : "Nein"}<br />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Tode: {deaths}<br />
                                Tötungen: {kills}<br />
                                NPC-Tötungen: {mobKills}<br />
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Spielzeit: {playTime}<br />
                                Ø Tägliche Spielzeit: {averagePlayTimePerDay}<br />
                                {characterData.current == null ? "" : <>Spielt seit: {new Date(characterData.current.first_played).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"}<br /></>}
                                {characterData.current == null ? "" : <>Zuletzt gespielt: {new Date(characterData.current.last_played).toLocaleDateString("de-DE", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) + " Uhr"}<br /></>}
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                Erledigte Quests: {solvedQuests}<br />
                                Erledigte Aktivitäten: {solvedActivities}<br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Alte Charaktere</h3>
                    </div>
                    <div className="card-body">
                        <CharacterHistoryList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Clients</h3>
                    </div>
                    <div className="card-body">
                        <ClientList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Verwarnungen</h3>
                    </div>
                    <div className="card-body">
                        <PunishmentList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>IP Adressen</h3>
                    </div>
                    <div className="card-body">
                        <AddressList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Tickets</h3>
                    </div>
                    <div className="card-body">
                        <TicketList filter={player} />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card card-primary">
                    <div className="card-header">
                        <h3>Punkte</h3>
                    </div>
                    <div className="card-body">
                        <PointsList filter={player} />
                    </div>
                </div>
            </div>
            </>
            }
        </div>
    )
}
   
export default PlayerSearch