import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../user/User";

export async function fetchData(apiPath, errorMessage, dataCallback, axiosSignal) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}${apiPath}`,
      {
        signal: axiosSignal,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );

    if (res.data === null || res.data === "") {
      return;
    }
    dataCallback(res.data);
  } catch (err) {
    if(axios.isCancel(err)) {
      return
    }
    console.error(err);
    toast.error(errorMessage);
  }
};

export async function fetchAndSetData(apiPath, errorMessage, setDataCallback, axiosSignal) {
  fetchData(apiPath, errorMessage, data => {
    setDataCallback(data)
  }, axiosSignal)
};
