import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import BasicTableComponent, { NumberRangeColumnFilter } from "../../components/basic.table"
import { getEMail, usePermissions } from "../../user/User"
import { useNavigate } from "react-router-dom"
import { fetchAndSetData } from "../../api/Axios"

const AddressList = ({filter}) => {
    const navigate = useNavigate()
    
    const columns =  [{
        Header: "Name",
        accessor: "name"
    }, {
        Header: "IP Adresse",
        accessor: "address"
    }, {
        Header: "Score",
        accessor: "score",
        Filter: NumberRangeColumnFilter,
        filter: 'between',
    }]
    
    const { permissions } = usePermissions(getEMail())
    useEffect(() => {
        if(permissions.addresses !== undefined && !permissions.addresses) {
            toast.error("Du hast keine Erlaubnis IP Adressen zu sehen!")
            navigate("/")
        }
    }, [permissions])

    const [addresses, setAddresses] = useState([])
    useEffect(() => {
        fetchAndSetData("/api/addresses" + (filter === undefined ? "" : "/" + filter), "IP Adressen konnten nicht geladen werden.", setAddresses)
    }, [filter])

    return (
        <div>
            <BasicTableComponent columns={columns} data={addresses} editUrlPart="address" />
        </div>
    )
}
   
export default AddressList